.downloadLinks {
  padding: 2rem;
  background-color: #ffffff;
  margin: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.downloadLinks img {
  margin: 0 10px;
}
