.header {
  background-color: #343a40;
  color: #ffffff;
  padding: 1rem;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

nav a {
  color: #ffffff;
}
