.testimonials {
  padding: 2rem;
  background-color: #ffffff;
  margin: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

blockquote {
  margin: 1rem 0;
  padding: 1rem;
  border-left: 4px solid #007bff;
  background-color: #f1f1f1;
}

blockquote footer {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #555;
}
